import Loadable from '@/components/loadable';
import { RoleEnum } from '@/types/enums';
import { Route } from '@/types/router';

export const customerAsyncRoutes: Route[] = [
  {
    path: '',
    name: 'customerIndex',
    component: Loadable({
      loader: () => import('@/modules/customer/overview')
    }),
    meta: {
      roles: [RoleEnum.CUSTOMER]
    }
  },
  {
    path: 'profile',
    name: 'profile',
    component: Loadable({
      loader: () => import('@/modules/customer/profile')
    }),
    meta: {
      roles: [RoleEnum.CUSTOMER]
    }
  },
  {
    path: 'billing',
    name: 'billing',
    component: Loadable({
      loader: () => import('@/modules/customer/billing')
    }),
    meta: {
      roles: [RoleEnum.CUSTOMER]
    }
  }
];
