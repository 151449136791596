import Loadable from '@/components/loadable';
import { RoleEnum } from '@/types/enums';
import { Route } from '@/types/router';

export const workspaceAsyncRoutes: Route[] = [
  {
    path: ':workspace_id',
    name: 'workspaceIndex',
    component: Loadable({
      loader: () => import('@/modules/workspace/dashboard')
    }),
    meta: {
      roles: [RoleEnum.CUSTOMER]
    }
  },
  {
    path: ':workspace_id/domains',
    name: 'workspaceDomain',
    component: Loadable({
      loader: () => import('@/modules/workspace/domain/List')
    }),
    meta: {
      roles: [RoleEnum.CUSTOMER]
    }
  },
  {
    path: ':workspace_id/domains/:domain_id',
    name: 'workspaceDomainDetail',
    component: Loadable({
      loader: () => import('@/modules/workspace/domain/Detail')
    }),
    meta: {
      roles: [RoleEnum.CUSTOMER]
    }
  },
  {
    path: ':workspace_id/api_keys',
    name: 'workspaceApiKey',
    component: Loadable({
      loader: () => import('@/modules/workspace/apikey/List')
    }),
    meta: {
      roles: [RoleEnum.CUSTOMER]
    }
  },
  {
    path: ':workspace_id/webhooks',
    name: 'workspaceWebhook',
    component: Loadable({
      loader: () => import('@/modules/workspace/webhook/List')
    }),
    meta: {
      roles: [RoleEnum.CUSTOMER]
    }
  },
  {
    path: ':workspace_id/webhooks/:webhook_id',
    name: 'workspaceWebhookDetail',
    component: Loadable({
      loader: () => import('@/modules/workspace/webhook/Detail/Detail')
    }),
    meta: {
      roles: [RoleEnum.CUSTOMER]
    }
  },
  {
    path: ':workspace_id/webhooks/:webhook_id/events/:webhook_event_id',
    name: 'workspaceWebhookDetailEventDetail',
    component: Loadable({
      loader: () => import('@/modules/workspace/webhook/Detail/Event')
    }),
    meta: {
      roles: [RoleEnum.CUSTOMER]
    }
  },
  {
    path: ':workspace_id/emails',
    name: 'workspaceEmail',
    component: Loadable({
      loader: () => import('@/modules/workspace/email/List')
    }),
    meta: {
      roles: [RoleEnum.CUSTOMER]
    }
  },
  {
    path: ':workspace_id/emails/:email_id',
    name: 'workspaceEmailDetail',
    component: Loadable({
      loader: () => import('@/modules/workspace/email/Detail/Detail')
    }),
    meta: {
      roles: [RoleEnum.CUSTOMER]
    }
  },
  {
    path: ':workspace_id/docs',
    name: 'workspaceDoc',
    component: Loadable({
      loader: () => import('@/modules/workspace/doc/Doc')
    }),
    meta: {
      roles: [RoleEnum.CUSTOMER]
    }
  }
];
