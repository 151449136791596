import { NavbarLink } from './interface';

import { IconDashboard, IconFileTypeDoc, IconLock, IconMail, IconWebhook, IconWorld } from '@tabler/icons-react';

export const workspaceNavbarLinks: NavbarLink[] = [
  {
    link: '/',
    label: 'overview',
    icon: IconDashboard
  },
  {
    link: '/domains',
    label: 'domains',
    icon: IconWorld
  },
  {
    link: '/api_keys',
    label: 'api_keys',
    icon: IconLock
  },
  {
    link: '/webhooks',
    label: 'webhooks',
    icon: IconWebhook
  },
  {
    link: '/emails',
    label: 'emails',
    icon: IconMail
  },
  {
    link: '/docs',
    label: 'documentation',
    icon: IconFileTypeDoc
  }
];
