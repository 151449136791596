const definitions = {
  required: 'Required',
  api_error: {
    required: 'required'
  },
  nav: {
    dashboard: 'Dashboard',
    users: 'Users',
    workspaces: 'Workspaces',
    overview: 'Overview',
    list: 'List',
    settings: 'Settings',
    my_profile: 'My profile',
    billing: 'Billing',
    price_plans: 'Price plans',
    activities: 'Activities',
    domains: 'Domains',
    logs: 'Logs',
    emails: 'Emails',
    webhooks: 'Webhooks',
    api_keys: 'API Keys',
    documentation: 'Documentation'
  },
  common: {
    get_support: 'Ask for help',
    send_email: 'Send email',
    get_started: 'Get started',
    get_started_step_one: 'Add your domain',
    get_started_step_two: 'Create your API Key',
    get_started_step_three: 'Send email by calling our api',
    just_3_steps: 'Just 3 steps',
    create_api_key: 'Create an API Key',
    docs: 'Docs',
    api_docs: 'API Docs',
    date: 'Date',
    webhook_event_details: 'Webhook event details',
    save: 'Save',
    edit_webhook: 'Edit webhook',
    edit_endpoint: 'Edit endpoint',
    disable_endpoint: 'Disable endpoint',
    delete_endpoint: 'Delete endpoint',
    listening_for: 'Listening for',
    events: 'events',
    event: 'Event',
    email_id: 'Email Id',
    select_events_to_listen: 'Select events to listen',
    add_webhook: 'Add webhook',
    disabled: 'Disabled',
    enabled: 'Enabled',
    created: 'Created',
    status: 'Status',
    dns_records: 'DNS Records',
    required: 'required',
    optional: 'Optional',
    dkim: 'dkim',
    dkim_spf: 'dkim & spf',
    dmarc: 'dmarc',
    dmarc_help:
      'We recommend setting up DMARC on your domain if you send more than 10,000 emails per day (or 5,000 emails to Gmail users).',
    dns_help:
      'Access the DNS settings page of the DNS provider and add all the following DNS records to the records section. We will automatically check the records.',
    type: 'Type',
    host_name: 'Host/Name',
    value: 'Value',
    priority: 'Priority',
    ttl: 'TTL',
    successfully_update: 'Successfully update',
    last_update: 'Last update',
    successfully_save: 'Successfully save',
    no_record_founds: 'No records found',
    something_went_wrong: 'We are having some issues',
    try_to_refresh_the_page: 'Can you try to refresh to page?',
    refresh_page: 'Refresh the page',
    loading: 'Loading',
    error: 'Error',
    success: 'Success',
    logout: 'Logout',
    select_media: 'Select media',
    page: 'Page',
    video: 'Video',
    rows_per_page: 'Rows per page',
    file_not_exceed: 'File not exceed',
    update: 'Update',
    enter_keyword_to_search: 'Enter a keyword to search',
    unable_to_load_data: 'Unable to load data',
    refresh: 'Refresh',
    last_updated_at: 'Last updated at',
    add_new: 'Add new',
    drag_file_or_click_files: 'Drag files here or click to select files',
    error_occured: 'Error occured',
    uploading: 'Uploading',
    select: 'Select',
    selected: 'Selected',
    no_result: 'No result',
    file_manager: 'File manager',
    your_browser_does_not_support_video_tag: 'Your browser does not support video tag',
    action: 'Action',
    add_domain: 'Add domain',
    add: 'Add',
    copy_to_clipboard: 'Copy to clipboard',
    never: 'Never',
    delete: 'Delete',
    enable_endpoint: 'Enable endpoint',
    full_access: 'Full access',
    are_you_sure_you_want_to_delete: 'Are you sure you want to delete',
    this_action_is_irreversible: 'This action is irreversible',
    are_you_sure_you_want_to_disable_this_endpoint: 'Are you sure you want to disable this endpoint?',
    are_you_sure_you_want_to_enable_this_endpoint: 'Are you sure you want to enable this endpoint?',
    select_image: 'Select image',
    confirm: 'Confirm',
    cancel: 'Cancel',
    edit: 'Edit',
    add_api_key: 'Add API Key',
    search_for_a_domain: 'Search for a domain',
    remove_this_domain: 'Remove this domain',
    done: 'Done',
    signing_secret: 'Signing secret',
    region: {
      'us-east-1': 'North Virginia (us-east-1)',
      'eu-west-2': 'London (eu-west-2)',
      'eu-west-1': 'Ireland (eu-west-1)',
      eu_west_1_soon: 'Ireland (eu-west-1) coming soon'
    },
    posted: 'Posted'
  },
  header: {
    settings: 'Settings',
    account: 'Account',
    end_impersonate: 'End impersonate',
    version: 'Version',
    logout: 'Logout'
  },
  dashboard_view: {
    meta_title: 'Dashboard',
    last_thirty_days: 'Last 30 days'
  },
  email_view: {
    meta_title: 'Emails',
    emails: 'Emails',
    email: 'Email',
    subject: 'Subject',
    to: 'To',
    from: 'From',
    body: 'Body',
    events: 'Events'
  },
  login_view: {
    title: 'Login',
    login: 'Login',
    email: 'Email',
    code: 'Code',
    request_login_code: 'Request login code',
    enter_your_email: 'Enter your email',
    enter_login_code: 'Enter login code',
    must_be_6_digits: 'Must be 6 digits'
  },
  domain_view: {
    domains: 'Domains',
    meta_title: 'Domains',
    domain: 'Domain',
    status: 'Status',
    region: 'Region',
    domain_help: 'It is a best practice to use a subdomain for proper reputation segmentation (e.g. email.domain.com)',
    region_help: 'Select a region close to your users for the best performance'
  },
  api_key_view: {
    api_keys: 'API Keys',
    view_api_key: 'View API Key',
    meta_title: 'API Keys',
    new_api_key: 'New API Key',
    total_uses: 'Total uses',
    last_used_at: 'Last used at',
    permissions: 'Permissions',
    domains: 'Domains',
    key: 'Key',
    secret_key: 'Secret key',
    name: 'Name',
    all_domains: 'All domains',
    you_can_only_see_this_key_once_store_it_safely: 'You can only see this key once. Store it safely.'
  },
  webhook_view: {
    meta_title: 'Webhooks',
    endpoint: 'Endpoint',
    webhooks: 'Webhooks'
  },
  profile_view: {
    email: 'Email',
    my_profile: 'My profile',
    title: 'Overview',
    billing: 'Billing',
    workspaces: 'Workspaces',
    work_in_progress: 'Work in progress',
    name: 'Name',
    workspace_name: 'Workspace name',
    create: 'Create',
    create_new_workspace: 'Create new workspace',
    select_workspace: 'Select workspace',
    add_new_workspace: 'Add new workspace'
  },
  subscription_view: {
    meta_title: 'Billing',
    billing: 'Billing',
    subscription: 'Subscription',
    manage: 'Manage',
    plan: 'Plan',
    billing_cancellation_help:
      'If you cancel the subscription, you need to resubscribe to gain the access to the dashboard'
  },
  notfound: {
    meta: {
      title: 'Page not found',
      description: 'Page not found'
    },
    title: 'Page not found'
  }
};

export default definitions;
