import Loadable from '@/components/loadable';
import { adminAsyncRoutes } from '@/router/routes/admin';
import { customerAsyncRoutes } from '@/router/routes/customer';
import { workspaceAsyncRoutes } from '@/router/routes/workspace';

import { Route } from '@/types/router';

const fixedRoutes: Route[] = [
  {
    path: 'login',
    name: 'Login',
    component: Loadable({
      loader: () => import('@/modules/auth/Login')
    })
  }
];

const notFoundRoute: Route = {
  path: '*',
  name: 'NotFound',
  component: Loadable({
    loader: () => import('@/modules/NotFound')
  }),
  hidden: true
};

const whiteList = fixedRoutes.map((route) => `/${route.path}`);

export { adminAsyncRoutes, customerAsyncRoutes, fixedRoutes, notFoundRoute, whiteList, workspaceAsyncRoutes };
