import Loadable from '@/components/loadable';
import { RoleEnum } from '@/types/enums';
import { Route } from '@/types/router';

export const adminAsyncRoutes: Route[] = [
  {
    path: '',
    name: 'index',
    component: Loadable({
      loader: () => import('@/modules/admin/dashboard')
    }),
    meta: {
      roles: [RoleEnum.ADMIN]
    }
  },
  {
    path: 'users',
    name: 'user',
    component: Loadable({
      loader: () => import('@/modules/admin/user/List')
    }),
    meta: {
      roles: [RoleEnum.ADMIN]
    }
  },
  {
    path: 'users/create',
    name: 'userCreate',
    component: Loadable({
      loader: () => import('@/modules/admin/user/Create')
    }),
    meta: {
      roles: [RoleEnum.ADMIN]
    }
  },
  {
    path: 'users/:id/update',
    name: 'userUpdate',
    component: Loadable({
      loader: () => import('@/modules/admin/user/Update')
    }),
    meta: {
      roles: [RoleEnum.ADMIN]
    }
  },
  {
    path: 'price_plans',
    name: 'pricePlan',
    component: Loadable({
      loader: () => import('@/modules/admin/price-plan/List')
    }),
    meta: {
      roles: [RoleEnum.ADMIN]
    }
  },
  {
    path: 'price_plans/create',
    name: 'pricePlanCreate',
    component: Loadable({
      loader: () => import('@/modules/admin/price-plan/Create')
    }),
    meta: {
      roles: [RoleEnum.ADMIN]
    }
  },
  {
    path: 'price_plans/:id/update',
    name: 'pricePlanUpdate',
    component: Loadable({
      loader: () => import('@/modules/admin/price-plan/Update')
    }),
    meta: {
      roles: [RoleEnum.ADMIN]
    }
  }
];
