import useGetApi from '@/hooks/useGetApi';
import { useTranslations } from '@/lib/i18n';
import { Box, Group, Menu, Text, UnstyledButton, rem } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import classes from './Footer.module.css';

export function Footer() {
  const { workspace_id } = useParams();
  const navigate = useNavigate();
  const t = useTranslations();

  const { data } = useGetApi<Array<{ id: string; name: string }>>('/v1/me/workspaces', 'meWorkspaces');

  const workspaces = data?.data || [];

  if (!workspace_id) return null;

  const workspace = workspaces.find((w) => w.id === workspace_id);

  return (
    <UnstyledButton className={classes.user}>
      <Group>
        <Menu position="right-end" withinPortal transitionProps={{ transition: 'fade-up' }}>
          <Menu.Target>
            <Group>
              <Box w={135}>
                <Text truncate inline fw={500}>
                  {workspace?.name}
                </Text>
              </Box>
              <IconChevronRight style={{ width: rem(14), height: rem(14) }} stroke={1.5} />
            </Group>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>{t('profile_view.workspaces')}</Menu.Label>
            {workspaces.map((c) => (
              <Menu.Item key={c.id} onClick={() => navigate(`/${c.id}`)}>
                {c.name}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      </Group>
    </UnstyledButton>
  );
}
