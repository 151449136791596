export enum RoleEnum {
  ADMIN = 'admin',
  CUSTOMER = 'customer', // owner
  CUSTOMER_MEMBER = 'customer_member' // workspace memeber
}

export enum SettingGroupEnum {
  SYSTEM = 'system'
}

export const ActivityLogActionTranslation: Record<string, string> = {
  create: 'created',
  update: 'updated',
  delete: 'deleted',
  modify: 'modified',
  upload: 'uploaded'
} as const;

export enum AppNameEnum {
  DASHBOARD_IMPERSONATING = 'dashboard_impersonating',
  ONLINE = 'online',
  DASHBOARD = 'dashboard'
}

export enum WorkspaceUserRoleEnum {
  OWNER = 'owner',
  MEMBER = 'member'
}

export enum RegionEnum {
  US_EAST_1 = 'us-east-1',
  EU_WEST_2 = 'eu-west-2'
}

export enum EmailStatusEventEnum {
  DELIVERED = 'delivered',
  REJECTED = 'rejected',
  FAILED = 'failed',
  DELIVERY_DELAYED = 'delivery_delayed', // server transient issue
  BOUNCED = 'bounced', // recipient server rejected completely
  OPENED = 'opened',
  CLICKED = 'clicked'
}
